body {
    height: 100vh;
}

body,
.main {
    overflow-y: hidden;
}

.header {
    height: 5vh;
    font-size: 2.4vh;
    color: white;
}

.header h1 {
    font-size: 4vh;
}

.header small {
    font-size: 1.5vh;
}

.hamburger {
    height: 2rem;
}

.footer {
    overflow-y: hidden;
}

.footer,
.footer img {
    height: 10px !important;
}

.tiny {
    font-size: 8px;
    height: fit-content;
}

.map {
    height: calc(99vh - 5vh - 25vh - 10px);
    width: 100%;
}

.profile {
    height: 25vh;
    width: 100%;
}

.left-menu {
    height: calc(99vh - 5vh - 10px);
}

.left-margin {
    flex: 0 0 275px;
}

.wind-buttons {
    display: table-cell;
    padding: 0;
    border-spacing: 0;
    line-height: 0;
}

.wind-buttons button {
    height: 30px;
}

.settings-collapse {
    position: absolute;
    top: calc(5vh + 2rem + 2px);
    left: 5px;
    z-index: 1000;
    background-color: white;
}

.info .label {
    font-weight: normal;
}

.info .value {
    font-weight: bold;
    text-align: right;
}

.route-list {
    overflow-y: scroll;
}

.route,
.launch {
    cursor: pointer;
}

.launch p,
.route p {
    margin-bottom: 4px;
}

.profile {
    width: 100%;
    height: 25vh;
}

.wind-rose {
    width: 50px;
    height: 50px;
}

.inline-icon {
    height: 1rem;
}

.ol-layers-control {
    right: 8px;
    top: 8px;
    background-color: rgba(255, 255, 255, 0.6);
}

.ol-layers-control:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

.ol-layers-control label {
    margin-left: 8px;
}

.ol-layers-control>div {
    padding: 8px;
}

.ol-layers-control>span>*:first-child {
    margin-left: auto;
}
