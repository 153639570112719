.animated-list-enter,
.animated-list-appear {
    opacity: 0;
    transform: scale(0.9);
}

.animated-list-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.animated-list-exit {
    opacity: 1;
}

.animated-list-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}